<template>
    <div class="storeinformation">
        <div class="storeinformation-wrap">
            <div class="storeinformation-wrap-top">
                <img :src="shopInfo.shop_logo" alt />
                <p>{{shopInfo.shop_name}}</p>
            </div>
            <ul class="storeinformation-wrap-bottom">
                <li>
                    <p>商家电话</p>
                    <div class="flex-between">
                        <input type="text" v-model="shopInfo.shop_phone" />
                        <p>点击可修改</p>
                    </div>
                </li>
                <li>
                    <p>店铺地址</p>
                    <p>{{shopInfo.shop_address}}</p>
                </li>
                <li>
                    <p>营业时间</p>
                    <p>{{shopInfo.business_hours}}</p>
                </li>
            </ul>
            <div class="storeinformation-wrap-footer">
                <p>注：店铺logo、地址、营业时间若要修改请联系平台客服</p>
            </div>
            <p class="store" @click="Modify">保存</p>
        </div>
    </div>
</template>

<script>
import { BuessDetail, Modify } from "../../../http/api";

export default {
    data() {
        return {
            merchat_phone: '',
            shopInfo: {}
        };
    },
        
    beforeCreate() {
        if (!this.$LStorage.getItem("shopUserInfo")) {
            this.$router.replace({
                path: "/merchatlogin"
            });
        }
    },
    mounted() {
        this.shopUserInfo = this.$LStorage.getItem("shopUserInfo");
        this.BuessDetail();
    },
    methods: {
        //获取商家详情
        async BuessDetail() {
            const res = await BuessDetail({
                data: {
                    shop_id: this.shopUserInfo.shop_id
                }
            });
            //console.log(res);
            this.shopInfo = res.data;
        },

        //点击修改商家电话
        async Modify() {
            const res = await Modify({
                data: {
                    shop_id: this.shopInfo.shop_id,
                    shop_phone: this.shopInfo.shop_phone
                }
            });
            if (res.code == "200") {
                let that = this;
                this.$toast.success({message:'保存成功', onClose:function(){
                    that.$router.go(-1)
                }}); 
            } else {
                this.$toast(res.msgs);
            }
        }
    },
    created() {}
};
</script>

<style lang="scss">
.storeinformation {
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    .flex-between {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 1.3rem);
    }

    .my-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.3rem;
        box-sizing: border-box;
        font-size: 0.38rem;
        background: #f4f4f4;
    }

    .storeinformation-wrap {
        width: 100%;
        height: 3.65rem;

        .storeinformation-wrap-top {
            width: 100%;
            height: 3.65rem;
            background: #fff;
            text-align: center;
            padding-top: 0.6rem;
            padding-bottom: 0.94rem;
            box-sizing: border-box;

            img {
                width: 1.56rem;
                height: 1.5rem;
                border-radius: 50%;
                border: 1px solid #fc6f15;
                margin-bottom: 0.23rem;
                object-fit: cover;
            }

            p {
                font-size: 0.32rem;
            }
        }

        .storeinformation-wrap-bottom {
            width: 100%;
            background: #fff;
            padding-left: 0.23rem;
            padding-right: 0.23rem;
            box-sizing: border-box;

            li {
                display: flex;
                align-items: center;
                font-size: 0.26rem;
                height: 1rem;
                border-bottom: 1px solid #f0f0f0;
                color: #333;

                p:nth-child(1) {
                    width: 1.3rem;
                    color: #666;
                }
            }
        }

        .storeinformation-wrap-footer {
            font-size: 0.26rem;
            color: #b9b9b9;
            margin-top: 0.45rem;
            margin-left: 0.23rem;
        }

        .store {
            width: calc(100% - 0.48rem);
            height: 0.84rem;
            text-align: center;
            background: #e14f2a;
            color: #fff;
            line-height: 0.84rem;
            border-radius: 0.42rem;
            font-size: 0.31rem;
            margin: 0 auto;
            margin-top: 1.22rem;
        }
    }
}
</style>
